const changeActiveCarouselPicture = () => {

    const clickPicture = (clickedPicture) => {
        const id = clickedPicture.dataset.pictureid;

        let carouselPictureItems = document.querySelectorAll('.carousel-item-picture');
        if (carouselPictureItems){
            carouselPictureItems.forEach((carouselPictureItem) => {
                carouselPictureItem.classList.remove("active");
                if (carouselPictureItem.dataset.pictureid == id) {
                    carouselPictureItem.classList.add("active");
                }
            });
        }            
    }

    let pictures = document.querySelectorAll('.salon-pictures-grid-picture');
    if (pictures){
        pictures.forEach((picture) => {
            picture.addEventListener('click', (event) => {
            clickPicture(picture);
        });
        });
    }
    
}
export default changeActiveCarouselPicture;