const acceptCookies = () => {
    var acceptCookiesEl = document.getElementById("accept-cookies");
    var cookiesBannerEl = document.getElementById("cookies-banner");
    if (acceptCookiesEl && cookiesBannerEl) {
        acceptCookiesEl.addEventListener('click', (event) => {
            Cookies.set('cookie_eu_consented', 'true', { expires: 365 });
            cookiesBannerEl.remove();
          });        
    }
}
export default acceptCookies;